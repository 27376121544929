import React, { useState, Component } from 'react';
import queryString from 'query-string';
import ApiService from './ApiService';
import { Container, FormControlLabel,FormHelperText, TextField, Divider, Button, Typography, Accordion, AccordionSummary, AccordionDetails, Box, Paper, CircularProgress,Backdrop } from '@mui/material';
import Alert from '@mui/material/Alert';
import CreditCardInput from 'react-credit-card-input';
  

class CheckoutApp extends Component {
	constructor(props) {
	  super(props);
	  let params = queryString.parse(window.location.search);
	  this.state = {
			qpId: params.id,
			qpTransaction: null,
			cardHolderName: null,
			cardNumber: '',
			cardExpiry: null,
			cardCvv: null,
			nameErr: false,
			errMsg: '',
			cardErr: false,
			paymentSuccess: false,
			paymentFailed: false,
			paymentError: null, 
			email: null,
			firstName: null,
			lastName: null,
			phone: null,
			city: null,
			state: null,
			country: null,
			zip: null,
			responseMessage: null,
			failed: false,
			success: false,
	  }
		this.handleCardHolderChange = this.handleCardHolderChange.bind(this);
		this.handleCardNumberChange = this.handleCardNumberChange.bind(this);
		this.handleCardExpiryChange = this.handleCardExpiryChange.bind(this);
		this.handleCardCvvChange = this.handleCardCvvChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
	}
	componentDidMount(){
		ApiService.retrieveQuickPayTransaction(this.state.qpId).then((res)=>{
			this.setState({qpTransaction: res.data});
		})
	}
	handleCardHolderChange(val){
		this.setState({nameErr: false, errMsg: '',failed: false});
		this.setState({cardHolderName: val.target.value});
	}
	handleCardNumberChange(val){		
		this.setState({cardErr: false,failed: false});
		this.setState({cardNumber: val.target.value});
	}
	handleCardExpiryChange(val){
		this.setState({cardErr: false,failed: false});
		this.setState({cardExpiry: val.target.value});
	}
	handleCardCvvChange(val) {
		this.setState({cardErr: false,failed: false});
		this.setState({cardCvv: val.target.value});
	}
	handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;

        this.setState({
            [inputName] : inputValue
        });        
    }
	handleSubmit() {
	 	if(this.state.firstName != null && this.state.firstName != '' 
			&& this.state.lastName != null && this.state.lastName != ''
			&& this.state.phone != null && this.state.phone != ''
			&& this.state.email != null && this.state.email != ''
			&& this.state.address != null && this.state.address != ''
			&& this.state.city != null && this.state.city != ''
			&& this.state.country != null && this.state.country != ''
			&& this.state.zip != null && this.state.zip != ''
			) {
				
				var request = Object.assign({}, this.state);
				ApiService.quickPaymentCheckout(request).then((res) => {
				if (res.data.status == 'OK') {
					this.setState({ failed: false, success: true, responseMessage: res.data.responseMessage });
					this.props.reloadData();
				}
				else
					this.setState({ failed: true, success: false, responseMessage: res.data.responseMessage })
				}).catch(e => {
					this.setState({ failed: true, responseMessage: "Error" })
				})
				}
	
	}
	
	render(){
		 
		return (  
					
				<Container maxWidth="sm" style={{marginTop: '1em'}} align="left">
					{this.state.failed && <Alert severity="error" elevation="0"> {this.state.responseMessage}</Alert>}
					{this.state.success && <Alert severity="success" elevation="0">{this.state.responseMessage}</Alert>}
					 <Paper style={{margin: '1em', padding: '1em'}} elevation={3}>
						 <div align="center" style={{marginBottom: '1em'}}><img src="logo.png" width="80" height="80"/> </div>
						 {this.state.failed && <Alert severity="error" elevation="0"> {this.state.responseMessage}</Alert>}
						{this.state.success && <Alert severity="success" elevation="0">{this.state.responseMessage}</Alert>}
						 <Typography variant="h6" style={{marginBottom: '1em',}}>Amount: {this.state.qpTransaction && this.state.qpTransaction.amount.toFixed(2)} {this.state.qpTransaction && this.state.qpTransaction.currency}</Typography>
				     
					  <Typography variant="h6" style={{marginBottom: '1em',}}>Card holder details</Typography>
							<TextField name="firstName" placeholder="First Name" fullWidth error={this.state.firstName==null}  size="small"
								value={this.state.firstName} onChange={this.handleInputChange} required helperText={this.state.firstName==null 
						&& "Value is required" }/>
						 
						 
							<TextField name="lastName" placeholder="Last Name" fullWidth error={this.state.lastName==null} size="small"
								value={this.state.lastName} onChange={this.handleInputChange} required helperText={this.state.lastName==null 
						&& "Value is required" }/>
						 
						 
							<TextField name="phone" placeholder="Phone" fullWidth error={this.state.phone==null} size="small"
								value={this.state.phone} onChange={this.handleInputChange} helperText={this.state.phone==null 
						&& "Value is required" }/>
						 
						<TextField name="email" placeholder="Email" fullWidth error={this.state.email==null}  margin="dense" type="email" size="small"
					value={this.state.email} onChange={this.handleInputChange} required helperText={this.state.email==null 
						&& "Value is required" }/>
						 
							<TextField name="address" placeholder="Address" fullWidth error={this.state.address==null} size="small"
								value={this.state.address} onChange={this.handleInputChange} helperText={this.state.address==null 
						&& "Value is required" }/>
						  
							<TextField name="city" placeholder="City" fullWidth error={this.state.city==null} size="small"
								value={this.state.city} onChange={this.handleInputChange} required helperText={this.state.city==null 
						&& "Value is required" }/>
						 
							{/*<TextField name="state" placeholder="State" fullWidth size="small"
								value={this.state.state} onChange={this.handleInputChange}  />*/}
						 
							<TextField name="country" placeholder="Country" fullWidth error={this.state.country==null} size="small"
								value={this.state.country} onChange={this.handleInputChange} helperText={this.state.country==null 
						&& "Value is required (Only 2 Character Country code e.g. US )" }/>
						 
							<TextField name="zip" placeholder="Zip" fullWidth error={this.state.zip==null} size="small"
								value={this.state.zip} onChange={this.handleInputChange} helperText={this.state.zip==null 
						&& "Value is required" }/>
						 
						<Typography variant="h6" style={{marginBottom: '1em',marginTop: '1em'}}>Card details</Typography>
						 <Box align="center" style={{margin: '1em'}}>
							<CreditCardInput required fullWidth
									cardNumberInputProps={{ value: this.state.cardNumber, onChange: this.handleCardNumberChange }}
		 							cardExpiryInputProps={{ value: this.state.cardExpiry, onChange: this.handleCardExpiryChange }}
		  							cardCVCInputProps={{ value: this.state.cardCvv, onChange: this.handleCardCvvChange }} />
							{this.state.cardErr && 
								<Typography variant="caption" color="red">Credit card details are mandatory</Typography>
							}
						 </Box>
						 
						 <Button variant="contained" color="primary" onClick={this.handleSubmit} fullWidth>Pay</Button>
						 
					</Paper>
				</Container>
			 
			);
	}
} export default CheckoutApp;