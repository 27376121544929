import axios from 'axios';

const API_URL = process.env.REACT_APP_HOST_URL;
const USER_API_BASE_URL = API_URL + '/reports/';

export const USER_NAME_SESSION_ATTRIBUTE_NAME = 'authenticatedUser'
export const MERCHANT_SESSION_ATTRIBUTE_NAME = 'loggedInMerchant'

class ApiService {
	
	getFromSessionStorate(key) {
		return sessionStorage.getItem(key);
	}
	setInSessionStorage(key, value) {
		sessionStorage.setItem(key, value);	
	}
	removeFromSessionStorage(key) {
		sessionStorage.removeItem(key);
	}
	retrieveQuickPayTransaction(qpId) {
		return axios.post(API_URL + '/res/retrieveQPTransaction', {
			"qpId" : qpId,
		});
	}
	quickPaymentCheckout(data) {
		return axios.post(API_URL + '/res/mtPaymentCheckout',  data);
	}
}
export default new ApiService();