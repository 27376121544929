import logo from './logo.svg';
import './App.css';
import { createTheme , ThemeProvider} from '@mui/material/styles';
import CheckoutApp from './CheckoutApp';

function App() {
	const theme = createTheme({
	  palette: {
	   primary: {
	      light: '#BC9F97',
	      main: '#A1796D',
	      dark: '#76554B',
	      contrastText: '#fff',
	    },
	    secondary: {
	      light: '#901D40',
	      main: '#62142C',
	      dark: '#531125',
	      contrastText: '#000',
	    },
	  },
	});
  return (
	<ThemeProvider theme={theme}>
    <div className="App">     
		<CheckoutApp />
    </div>
	</ThemeProvider>
  );
}

export default App;
